.rbc-day-slot .rbc-events-container {
  margin-right: 0px;
}

.rbc-event {
  align-items: center;
}

.rbc-event-content {
  width: inherit !important;
  display: flex;
  align-items: center;
}